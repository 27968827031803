<template>
	<div class="container">
		<div class="succ-bg relative">
			<img src="../assets/initiate/succ_bg.png" class="succ-bg-img"/>
			<div class="flex-column align-center succ-sec">
				<img src="../assets/initiate/toupiao_succ.png" class="initial-succ-icon"/>
				<div class="initiate-succ-text">您已成功投票！</div>
				<div class="initiate-succ-btn" @click="confirm()">确认</div>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	
	Vue.use(Vant);
	export default {
		data() {
			return {
			}
		},
		computed: {
			
		},
		methods: {
			confirm(){
				this.$router.go(-1)
			},
		},
		mounted() {
		}
	}
</script>

<style scoped="scoped">
	.succ-bg{
		width: 100%;
		height: 100%;
		background: #F5F5F5;
	}
	.succ-bg-img{
		width: 100%;
		height: 100%;
	}
	.succ-sec{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 650px;
		height: 1250px;
		background: #FFFFFF;
		box-shadow: 0px 2px 26px 0px rgba(0,0,0,0.06);
		border-radius: 20px;
		margin: auto auto;
	}
	.initial-succ-icon{
		width: 324px;
		height: 258px;
		margin-top: 141px;
	}
	.initiate-succ-text{
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 42px;
		margin-top: 78px;
	}
	
	.initiate-succ-btn{
		width: 500px;
		height: 80px;
		background: #F7B500;
		border-radius: 40px;
		font-size: 32px;
		font-weight: bold;
		color: #000000;
		line-height: 80px;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 132px;
	}
</style>